import React, {useState} from 'react'
import contentCopy from '../assets/text/unstuck-copy.json'
import strategyImg from '../assets/images/unstuck/strategy.png'
import salesImg from '../assets/images/unstuck/sales.webp'
import brandmgmtImg from '../assets/images/unstuck/brand_mgmt.webp'

function GetUnstuck() {

  const [displayedContent, setDisplayedContent] = useState(contentCopy['strategy'])
  const [imgToDisplay, setImgToDisplay] = useState(strategyImg)

  const handleHover = (e) => {
    setDisplayedContent(contentCopy[e.target.id])
    
    switch (e.target.id) {
      case 'strategy':
        setImgToDisplay(strategyImg)
        break;
      case 'sales':
        setImgToDisplay(salesImg)
        break;
      case 'brand_mgmt':
        setImgToDisplay(brandmgmtImg)
        break;
      default:
        break;
    }
  }


  return (
    <section>
      <div className='get-unstuck-container'>
        <h2 className='underline-heading'>I can help you get unstuck</h2>
        <div className='unstuck-content-container'>
          <div className='unstuck-menu'>
            {Object.keys(contentCopy).map((item, index) => 
              (<h3 key={`heading-index-${index}`} id={item} onMouseOver={handleHover}>{contentCopy[item].heading}</h3>)
            )}
          </div>
          <div className='unstuck-content'>
            <img src={imgToDisplay} alt="" />
            <p>{displayedContent.text}</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default GetUnstuck