import React from 'react'

function Button({text}) {
  return (
    <div className='hero-button'>
      <p>{text}</p>
    </div>
  )
}

export default Button