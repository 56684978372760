import React, {useEffect, useState} from "react";
import {GiHamburgerMenu} from 'react-icons/gi'

function Navbar() {

  // horizontal, dropdown
  const [hamburgerVisibility, setHamburgerVisibility] = useState('hidden')
  const [listLayout, setListLayout] = useState('')
  const [listVisibility, setListVisibility] = useState('visible')

  useEffect(() => {
    const handleResize = () => {
      if(window.innerWidth <= 750){
        // setDisplayType('dropdown')
        setHamburgerVisibility('visible')
        setListLayout('force-newline-wrap nav-list-block')
        setListVisibility('util-hidden')
        // setNewLineWrap('force-newline-wrap')
      } else {
        setHamburgerVisibility('hidden')
        setListLayout('nav-list-flex')
        setListVisibility('visible')
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize)
  }, [])

  const handleClick = () => {
    console.log('clicking!')
    setListVisibility((prev) => {
      if(prev === 'util-hidden'){
        return 'visible'
      } else {
        return 'util-hidden'
      }
    })
  }

  console.log(window.onclick);

  return (
    <nav>
      <ul className="nav-container">
        {hamburgerVisibility === 'hidden' ? "" : 
        <li>
          <GiHamburgerMenu 
            id="hamburger"
            size={35} 
            className="nav-hamburger"
            color="#F5F5F5" 
            onClick={handleClick}/>
        </li>}
        <li>
            <a href="/" className="list-item logo">
              Andreas<b>Rhodes</b>
            </a>
          </li>

        <div className={`nav-list ${listLayout} ${listVisibility}`}>
          <li>
            <a href="/" className="list-item list-animation">
              Home
            </a>
          </li>
          <li>
            <a href="#Services" className="list-item list-animation">
              Services
            </a>
          </li>
          <li>
            <a href="#About-Me" className="list-item list-animation">
              About
            </a>
          </li>
          <li>
            <a href="#Testimonial" className="list-item list-animation">
              Testimonials
            </a>
          </li>
          <li>
            <a href="#Contact" className="list-item list-animation">
              Contact
            </a>
          </li>
        </div>       
      </ul>
    </nav>
  );
}

export default Navbar;
