import React from 'react'

function ExpertiseCard({title, pct_fill}) {


  return (
    <div className='expertise-card-container'>
      <div className='expertise-text'>
        <h4>{title}</h4>
        <p className=''>{pct_fill}%</p>
      </div>
      <div className='expertise-fill'>
        <div className='expertise-background'></div>
        <div className='expertise-foreground' style={{width:`${pct_fill}%`}}></div>
      </div>
    </div>
  )
}

export default ExpertiseCard