import React from 'react'

function WorkExperienceCard({title, subheading, blurb, imgURL}) {

  // Behaviour:
    // Card gets bigger on hover - expand horizontal, expand verticalliy
    // Image gets bigger within container
    // Text gets bigger on hover

  const image = require(`../assets/images/work_experience/${imgURL}`)
  
  return (
    <div className='work-exp-card'>
      <div className='work-exp-card-img' style={{backgroundImage: `url("${image}")`}}>
      </div>
      <div className='text-content'>
        <div className='subheading'>{subheading}</div>
        <h3 className='title'>{title}</h3>
        <p className='blurb'>{blurb}</p>
      </div>
    </div>
  )
}

export default WorkExperienceCard