import React from 'react'
import img from '../assets/images/about-me-background.png'

function AboutMe() {

  return (
    <section className='util-gray-background' id='About-Me'>
      <div className='about-container'>
        <img src={`${img}`} className='about-img' alt="" />
        <div className='about-content-container'>
          <h2 className='about-heading'><span>So who is</span> <span>Andreas Rhodes?</span></h2>
          <p className='about-blurb'>
            I was born in Glasgow and grew up travelling through Europe with my parents, who were both professional photographers. They installed a deep curiousity at a young age - always pushing me to look beyond the obvious and into the core of what I was observing.
          </p>
          <p className='about-blurb'>
            After university I worked for a number of different companies, but found my calling when I started working with technology start-ups. 
          </p>
          <p className='about-blurb'>
            When I'm not working, I'll be at the gym, working on my vintage motorcycles or simply reading to unwind. I'm currently based in Nakanocho, near Gion in Kyoto.</p>   
        </div>
      </div>
    </section>
  )
}

export default AboutMe