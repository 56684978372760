import React from 'react'
import Hero from '../sections/Hero'
import Introduction from '../sections/Introduction'
import GetUnstuck from '../sections/GetUnstuck'
import Testimonial from '../components/Testimonial'
import Expertise from '../sections/Expertise'
import HowIveHelped from '../sections/HowIveHelped'
import ThoughtLeadership from '../sections/ThoughtLeadership'
import AboutMe from '../sections/AboutMe'
import Contact from '../sections/Contact'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'

function Landing() {
  return (
    <>
      <Navbar/>
      <Hero/>
      <Introduction/>
      <GetUnstuck/>
      <Testimonial 
        clientName={'Clay Johnson'} 
        clientPosition={'Business Director at Wax Inc'}
        quote={"I am very happy with the services Andreas provided. He was a pleasure to work with, incredibly insightful in his comments and always enthusiastic. Working with our executive team, he helped us introduce a new brand strategy in record time, helping us pivot during a critical during the 2020 pandemic."} 
        imgURL={'clay-johnson-profile.png'}/>
      <Expertise/>
      <HowIveHelped/>
      <Testimonial 
        clientName={'Margot Thierry'} 
        clientPosition={'Founder, Creative Lead at Plankton Media LLC'}
        quote={"Andreas was a joy to work with. In addition to his experience he brought a youthful exuberance to every meeting and client interaction, sparking joy, enthusiasm and motivation within the team. I would recommend Andreas to anyone in a heartbeat."} 
        imgURL={'margot-thierry.webp'}/>
      <ThoughtLeadership/>
      <AboutMe/>
      <Contact/>
      <Footer/>
    </>
  )
}

export default Landing