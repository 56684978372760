import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Landing from './pages/Landing'

function App() {
  return (
    <div className="App">

      <Router>  
        <Routes>
          <Route path='/' element={<Landing/>} ></Route>
        </Routes>
      </Router>

    </div>
  );
}

export default App;
