import React from 'react'
import ExpertiseCard from '../components/ExpertiseCard'
import SideShot from '../assets/images/andreas-side-shot.png'

function Expertise() {
  return (
    <section className='expertise-section' id="Services">
      <img src={SideShot} alt="" className='expertise-side-shot'/>
      <div className='expertise-text-content'>
        <h2 className='underline-heading'>My Expertise</h2>
        <p>Helping you get unstuck is my speciality - and from years of industry experience, I have a number of areas I can help you in.</p>
        <ExpertiseCard title={"Strategy"} pct_fill={90} border={'expertise-card-container-border'}/>
        <ExpertiseCard title={"High Value Sales Advisory"} pct_fill={85}/>
        <ExpertiseCard title={"Brand Management"} pct_fill={80}/>
        <ExpertiseCard title={"Product Management"} pct_fill={78}/>
        <ExpertiseCard title={"Organisation Transformational Change"} pct_fill={76}/>
      </div>
    </section>
  )
}

export default Expertise