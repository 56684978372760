import React from 'react'
import {Link} from 'react-router-dom'
import {MdOutlineThumbUpAlt, MdOutlineShare} from 'react-icons/md'
import {BsChatLeft} from 'react-icons/bs'

function ThoughtLeadershipCard({category, title, blurb, body, imageURL, dateCreated, index}) {

  return (
    <article className='' key={`${index}-${dateCreated}`} >
      <div className='blog-item'>
        <img src={`http://${imageURL}`} className='blog-img' alt="" />
        <div className='blog-content'>
          <div className='blog-subheading'>
            {category.map((item, index) => {
              return <span key={`${index}${item}`}> {item} </span>
            })} 
            <span className='blog-date'>{dateCreated}</span>
          </div>
          <h3 className='blog-title'>{title}</h3>
          <p className='blog-body'>{blurb}</p>
          <div className='blog-social-grouping'>
            <div className='social-media-icons'>
              <Link to="/" className='blog-read-more '>Read More</Link>
              <Link to="/" className='blog-social-media'>
                <MdOutlineThumbUpAlt size={25}/>
              </Link>
              <Link to="/" className='blog-social-media'>
                <BsChatLeft size={25}/>
              </Link>
              <Link to="/" className='blog-social-media'>
                <MdOutlineShare size={25}/>
              </Link>
            </div>
          </div>
        </div>
      </div>

    </article>
  )
}

export default ThoughtLeadershipCard