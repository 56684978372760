import React from 'react'
import AccreditationPill from './AccreditationPill'

function Footer() {
  return (
    <div className='footer-container'>
      <h3>Andreas Rhodes Consulting</h3>
      <div className='internal-links'> 
        <a className='footer-link' href="/#">Home</a>
        <a className='footer-link' href="/#About-Me">About</a>
        <a className='footer-link' href="/#Services">Services</a>
        <a className='footer-link' href="/#Contact">Contact</a>
      </div>
      <div className='social-links'>
        <a href="https://www.instagram.com/willbl/?hl=en" target='_blank' rel="noreferrer">
          <AccreditationPill text='Ig' fillColor='office-blue'/>
        </a>
        <a href="https://www.linkedin.com/groups/71898/" target='_blank' rel="noreferrer">
          <AccreditationPill text='Li' fillColor='office-blue'/>
        </a>
        <a href="https://www.iod.com/" target='_blank' rel="noreferrer">
          <AccreditationPill text='Id' fillColor='office-blue'/>
        </a>
        <a href="https://twitter.com/paulg" target='_blank' rel="noreferrer">
          <AccreditationPill text='Tw' fillColor='office-blue'/>
        </a>
      </div>
      <hr/>
      <div>
        <p>Website by <a href="https://alan-povall.com" rel='noreferrer' target='_blank' className='footer-link'>Alan Povall</a></p>
      </div>
    </div>
  )
}

export default Footer