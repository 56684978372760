import React from 'react'
import topQuoteMark from '../assets/images/top-quote.png'
import bottomQuoteMark from '../assets/images/bottom-quote.png'

function Testimonial({clientName, clientPosition, quote, imgURL}) {

  const image = require(`../assets/images/testimonials/${imgURL}`)

  return (
    <section className='util-gray-background' id="Testimonial">
      <figure className='testimonial-container'>
        <img className="bottom-quote" src={bottomQuoteMark} alt="Background top decorative quote mark" />
        <div className='testimonial-image-container'>
          <img className='client-image'src={image} alt={clientName} />
          <img className="top-quote" src={topQuoteMark} alt="Background top decorative quote mark" />
        </div>
        <div>
          <blockquote className='quote'>{quote}</blockquote>
          <figcaption>
            <cite>
              <h4 className='client-name'>{clientName}</h4>
              <p className='client-position'>{clientPosition}</p>
            </cite>
          </figcaption>
        </div>
      </figure>
    </section>
  )
}

export default Testimonial