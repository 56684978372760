import React, {useEffect, useState} from 'react'
import ThoughtLeadershipCard from '../components/ThoughtLeadershipCard'


function ThoughtLeadership() {

  const [blogEntries, setBlogEntries] = useState([])
  
  useEffect(() => {
    const getEntries = async () => {
    
      let contentful = require('contentful')

      const client = contentful.createClient({
        space: "x8hjaucvbzgy",
        accessToken: "f4Z9R4te1k_2RwqAaeY_5jH5YA51ouoehh-yLsyyaik"
      })
      let activeBlogs = []
      let entries = await client.getEntries()
      entries.items.forEach((entry) => {
        activeBlogs.push({
          title: entry.fields.postTitle,
          category: entry.fields.postCategory,
          body: entry.fields.postBody.content,
          blurb: entry.fields.blurb,
          imgURL: entry.fields.postImage.fields.file.url,
          creationDate: entry.fields.postCreatedDate
        })
      });
      setBlogEntries(activeBlogs)
    }
    getEntries()

  },[])

  return (
    <section className='util-width-80'>
      <h2 className='underline-heading blog-heading'>Thought Leadership</h2>
      {blogEntries.map((item, index) => {
        return <div key={`${index}-blogpost`}>
            <ThoughtLeadershipCard
            category={item.category}
            title={item.title}
            blurb={item.blurb}
            body={item.body}
            imageURL={item.imgURL}
            dateCreated={new Date(item.creationDate).toLocaleDateString('en-US',{timezone: 'Pacific/Auckland'})}
            index={index}
          />    
        </div>

      })}


    </section>
  )
}

export default ThoughtLeadership