import React from 'react'
import IntroProfile from '../assets/images/andreas_rhodes_intro.png'
import AccreditationPill from '../components/AccreditationPill'

function Introduction() {
  return (
    <section className='intro-section'>
      {/* Flexbox */}
      <div className='intro-container'>
        {/* LHS Item */}
        <img className='intro-profile-img' src={IntroProfile} alt="Andreas working at a laptop looking handsome." />
        {/* RHS Item */}
        <div className='intro-text'>
          <h4>BRANDING // PRODUCT // STRATEGY</h4>
          <h2>Hi, I'm Andreas Rhodes</h2>
          <p className='intro-blurb'>
            If you are looking to grow your business or run it better, I'm here to help. As a business consultant that has worked in a variety of sectors - from aviation to luxury vehicles and coffee supply - I can help provide you with the tools and external views you need to make your business more successful. 
          </p>
          <p className='intro-blurb'>Whether you need help with marketing, financial, and operational planning, I can help you get fromk where you are to where you want to be, faster.</p>

          <div className='intro-accreditation-pills'>
            <AccreditationPill text='PhD' fillColor='tan-suit'/>
            <AccreditationPill text='MSc' fillColor='tan-suit'/>
            <AccreditationPill text='BE' fillColor='tan-suit'/>
          </div>

        </div>
      </div>
    </section>

  )
}

export default Introduction