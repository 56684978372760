import React, {useState, useEffect} from 'react'
import {GiPlainCircle} from 'react-icons/gi'
import {BiCheck} from 'react-icons/bi'
import {ImSpinner10} from 'react-icons/im'

function Contact() {

  const [submitStatus, SetSubmitStatus] = useState('inactive')
  const [displayStatusSpinner, setDisplayStatusSpinner] = useState('util-hidden')
  const [displayStatusCheck, setDisplayStatusCheck] = useState('util-hidden')
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleClick = (e) => {
    e.preventDefault()
    SetSubmitStatus('submitting')
    setButtonDisabled(true)
  }

  useEffect(() => {
    if(submitStatus==='submitting'){
      setDisplayStatusSpinner('display-block')
      setTimeout(() => {
        setDisplayStatusSpinner('util-hidden')
        setDisplayStatusCheck('display-block')
        SetSubmitStatus('submitted')
      },2000)

    }
    if(submitStatus==='submitted'){
      setTimeout(() => {
        setDisplayStatusCheck('util-hidden')
        SetSubmitStatus('inactive')
        setButtonDisabled(false)
      },3000)
    }

  },[submitStatus])

  return (
    <section className='contact-section' id='Contact'>
      <div className='contact-content'>
        <h2>Ready to make a change?</h2>
        <h2>Get in touch</h2>
        <p>Have a project in mind that you think I'd be a great fit for it? Get in touch via the form below, I always look to respond within 24 hours.</p>
        <div>
          <h4>Contact Info :</h4>
          <p><GiPlainCircle size={25} color="#90ACF7"/> Contact Number: (+81) 182 942 017</p>
          <p><GiPlainCircle size={25} color="#90ACF7"/> Email: hello@andreas-rhodes.com</p>
          <p><GiPlainCircle size={25} color="#90ACF7"/> Address: 243 Nakanochō, Kyoto, Japan</p>
        </div>

      </div>
      <div className='contact-form'>
        <form action="/">
          <div>
            <label htmlFor="full-name">Full Name</label>
            <input type="text" id='full-name' placeholder='What is your full name?'  />
          </div>

          <div>
            <label htmlFor="email">Email</label>
            <input type="email"placeholder='What is your email?' />
          </div>

         <div>
          <label htmlFor="message">Message</label>
          <textarea id='message' rows='10' cols='33' placeholder='Enter your message'></textarea>
         </div>

         <div className='button-container'>
          {/* <div className='contact-icons'> */}
            <ImSpinner10 className={`${displayStatusSpinner} spinner form-icon`} size={35} color="#fff" />
            <BiCheck className={`${displayStatusCheck} form-check form-icon`} size={45} color="#fff" />
          {/* </div> */}

          <button type='submit' onClick={handleClick} disabled={buttonDisabled}>
            Send Message
          </button>
         </div>
        </form>

      </div>

    </section>
  )
}

export default Contact