import React from 'react'
import Button from '../components/Button'
import heroProfile from '../assets/images/andreas_hero.jpg'
// import HeroBG from '../assets/images/hero_bg.svg'

function Hero() {
  return (
    <section className='hero-section'>
      <div className='hero-content-container'>
        <div className='hero-content'>
          <h4>BRANDING // PRODUCT // STRATEGY</h4>
          <h1>Brand and Strategy Development for SMEs</h1>
          <p className='intro-blurb'>
          Andreas Rhodes - branding, product management and strategy specialist. Focusing on leveraging agile frameworks to fast track organisational changes, new product development and brand identity. </p>
          <p className='intro-blurb'>
          With over a 15 years of experience working with a range of companies in many sectors, Andreas brings a wealth of experience to help you move your business to the next level. 
          </p>
          <div className='hero-button-container'>
            <a href="#Experience"><Button text='Experience'/></a>
            <a href="#Services"><Button text='Capability'/></a>
          </div>

        </div> 
        <div className='hero-img-container'>
          <img src={heroProfile} alt="" />
        </div>
      </div>     
    </section>
  )
}

export default Hero
