import React from 'react'

function AccreditationPill({text, fillColor}) {
  
  return (
    <p className='accreditation-pill' style={{backgroundColor:`var(--${fillColor})`}}>
      {text}
    </p>
  )
}

export default AccreditationPill