import React from 'react'
import WorkExperienceCard from '../components/WorkExperienceCard';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";



function HowIveHelped() {
  return (
    <section className='' id='Experience'>
      <div className='util-width-80'>
        <h2 className='underline-heading'>How I’ve Helped Companies</h2>
        <p>Here's a small selection of the many excellent companies I've had the privilege of working with over the last few years.</p>
      </div>
      <div className='work-exp-container'>
        <Swiper
          // slidesPerView={1}
          // spaceBetween={10}
          // loop={true}
          // pagination={{
          //   clickable: true,
          // }}
          breakpoints={{
            360: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
            1920: {
              slidesPerView: 5,
              spaceBetween: 70,
            },
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
            <WorkExperienceCard 
              title={"Muscle Coast Customs"} 
              subheading={"Strategy & Marketing"} 
              blurb={"I was engaged to help Muscle Coast Customs run a branding campaign to target Silicon Valley tech executives. The campaign was successful, boosting sales in their targeted demographic by 28%."} 
              imgURL={"muscle-car.jpg"}>
            </WorkExperienceCard>
          </SwiperSlide>
          <SwiperSlide>
            <WorkExperienceCard 
              title={"Unnamed Roasters & Grinds"} 
              subheading={"Strategy & Branding"} 
              blurb={"Unnamed engaged me to help bring a new line of pre-ground beans to market, focusing on specifically on lawyers and doctors who were short of time, but still wanted to be able to enjoy great coffee with their clients."} 
              imgURL={"unnamed-roasters.jpg"}>
            </WorkExperienceCard>
          </SwiperSlide>
          <SwiperSlide>
            <WorkExperienceCard 
              title={"United Airlines LLC"} 
              subheading={"Sales Advisory"} 
              blurb={"This project for United Airlines, setting up a new sales branch to help promote holiday package add-ons to travellers, as a means to diversify revenue risk. 3 years on, they are now the top performing sales team for UA."} 
              imgURL={"airport.jpg"}>
            </WorkExperienceCard>
          </SwiperSlide>
          <SwiperSlide>
            <WorkExperienceCard 
              title={"Bohemian Bakery and Pizzeria"} 
              subheading={"Brand & Marketing"} 
              blurb={"This was a fun side project helping set up the branding for a local bakery. I organised product shoots, advertising and negotiating supply agreements. They are currently rated the top bakery in Rugensburg, Germany."} 
              imgURL={"bakery.jpg"}>
            </WorkExperienceCard>
          </SwiperSlide>
          <SwiperSlide>
            <WorkExperienceCard 
              title={"Dogana Moto d'Italia"} 
              subheading={"Branding & Marketing"} 
              blurb={"As a vintage motorcycle enthusiast, I jumped at the opporunity to work with Dogana Moto d'Italia. I assisted with an ad campaign to promote their new line of semi-customised cafe racers to Fortune 500 sales executives."} 
              imgURL={"cafe-racer.jpg"}>
            </WorkExperienceCard>
          </SwiperSlide>
          <SwiperSlide>
            <WorkExperienceCard 
              title={"JTB Travel Japan"} 
              subheading={"Strategy & Marketing"} 
              blurb={"Working closely with the Offshore Outreach team, I helped JTB set up three more branches in New York, Delhi and Sydney to help promote tourism to Japan. All three branches are now in the top 10 globally for JTB."} 
              imgURL={"japan.jpg"}>
            </WorkExperienceCard>
          </SwiperSlide>

        </Swiper>
      </div>

    </section>
  )
}

export default HowIveHelped